export interface ContactIconData {
    link: string;
    name: string;
    altText: string;
}

export const contactIconsList: ContactIconData[] = [
    {
        link: 'mailto:amos.m.rose@gmail.com',
        name: 'fa fa-envelope-square',
        altText: 'Email Icon',
    },
    {
        link: 'https://www.linkedin.com/in/amos-rose/',
        name: 'fa fa-linkedin-square',
        altText: 'LinkedIn Logo Icon',
    },
    {
        link: 'https://github.com/AmMiRo',
        name: 'fa fa-github-square',
        altText: 'GitHub Logo Icon',
    },
    // {
    //   link: "https://twitter.com/amosmrose",
    //   name: "fa fa-twitter-square",
    //   altText: "Twitter Logo Icon",
    // },
];
