import { ChangeEvent, MouseEvent } from 'react';
import styled from 'styled-components';
import colors from 'global/colors';
import breakpoints from 'global/breakPoints';
import { Message } from './fixtures';
import { Button } from 'components/Button';

interface Props {
    message: Message;
    handleChange: (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => void;
    handleSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
    handleClear: (e: MouseEvent<HTMLButtonElement>) => void;
}

function ContactFormView(props: Props) {
    const { message, handleChange, handleSubmit, handleClear } = props;

    return (
        <ContactForm>
            <TopInputs>
                <ContactLabel>
                    <ContactInput
                        id="name"
                        name="name"
                        type="text"
                        value={message.name}
                        onChange={handleChange}
                        placeholder={NAME_PLACEHOLDER}
                    />
                </ContactLabel>
                <ContactLabel>
                    <ContactInput
                        id="email"
                        name="email"
                        type="text"
                        value={message.email}
                        onChange={handleChange}
                        placeholder={EMAIL_PLACEHOLDER}
                    />
                </ContactLabel>
            </TopInputs>
            <ContactLabel>
                <ContactInput
                    id="subject"
                    name="subject"
                    type="text"
                    value={message.subject}
                    onChange={handleChange}
                    placeholder={SUBJECT_PLACEHOLDER}
                />
            </ContactLabel>
            <ContactLabel>
                <ContactInput
                    as="textarea"
                    id="message"
                    name="message"
                    value={message.message}
                    onChange={handleChange}
                    placeholder={MESSAGE_PLACEHOLDER}
                    rows={4}
                />
            </ContactLabel>
            <ContactButtonWrapper>
                <ContactFormButton
                    backgroundColor={colors.darkGray}
                    textColor={colors.white}
                    height={40}
                    width={150}
                    onClick={handleSubmit}
                >
                    {MESSAGE_SUBMIT_BUTTON_TEXT}
                </ContactFormButton>
                <ContactFormButton
                    backgroundColor={colors.darkGray}
                    textColor={colors.white}
                    height={40}
                    width={150}
                    onClick={handleClear}
                >
                    {CLEAR_MESSAGE_BUTTON_TEXT}
                </ContactFormButton>
            </ContactButtonWrapper>
        </ContactForm>
    );
}

const ContactForm = styled.form`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 50px;
    max-width: 90%;
    width: 800px;
`;

const TopInputs = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${breakpoints.medium}) {
        flex-direction: column;
        justify-content: center;
    }
`;

const ContactLabel = styled.label`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ContactInput = styled.input`
    background-color: ${colors.darkGray};
    border: none;
    border-radius: 7.5px;
    box-shadow:
        inset 0px 2px 3px 1px rgba(0, 0, 0, 0.05),
        0px 1px 0px 0px rgba(255, 255, 255, 0.025);
    color: ${colors.white};
    font-family: 'Roboto', 'sans-serif';
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 5px;
    outline: none;
    padding: 10px;
    width: 100%;
`;

const ContactButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 15px;
    max-width: 80%;
    width: 400px;
`;

const ContactFormButton = styled(Button)`
    box-shadow: none;
    font-size: 1.6rem;
    margin: 15px;

    &:hover {
        box-shadow: none;
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }

    &:active {
        opacity: 1;
    }
`;

const NAME_PLACEHOLDER = 'Name';

const EMAIL_PLACEHOLDER = 'Email';

const SUBJECT_PLACEHOLDER = 'Subject';

const MESSAGE_PLACEHOLDER = 'Message';

const MESSAGE_SUBMIT_BUTTON_TEXT = 'Submit';

const CLEAR_MESSAGE_BUTTON_TEXT = 'Clear';

export default ContactFormView;
