import styled from 'styled-components';
import colors from 'global/colors';
import breakpoints from 'global/breakPoints';
import Logo from 'images/AR-logo.png';

function Header() {
    return (
        <HeaderWrapper>
            <LogoWrapper>
                <LogoImg src={Logo} alt={LOGO_ALT_TEXT} />
            </LogoWrapper>
            <NavWrapper>
                <NavLink href="#home">{HOME_LINK_TEXT}</NavLink>
                <NavLink href="#about">{ABOUT_LINK_TEXT}</NavLink>
                <NavLink href="#skills">{SKILLS_LINK_TEXT}</NavLink>
                <NavLink href="#contact">{CONTACT_LINK_TEXT}</NavLink>
            </NavWrapper>
        </HeaderWrapper>
    );
}

const HeaderWrapper = styled.header`
    align-items: center;
    background-color: ${colors.gray};
    display: flex;
    height: 75px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;

    @media (max-width: ${breakpoints.large}) {
        justify-content: center;
    }
`;

const LogoWrapper = styled.div`
    height: 100%;
    margin-left: 5%;

    @media (max-width: ${breakpoints.large}) {
        display: none;
    }
`;

const LogoImg = styled.img`
    height: 100%;
`;

const NavWrapper = styled.nav`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 5%;
    width: 400px;

    @media (max-width: ${breakpoints.large}) {
        margin: 15px;
    }
`;

const NavLink = styled.a`
    color: ${colors.white};
    font-size: 2rem;
    text-decoration: none;

    @media (max-width: ${breakpoints.small}) {
        font-size: 1.4rem;
    }
`;

const LOGO_ALT_TEXT = 'A|R logo';

const HOME_LINK_TEXT = 'Top';

const ABOUT_LINK_TEXT = 'About';

const SKILLS_LINK_TEXT = 'Skills';

const CONTACT_LINK_TEXT = 'Contact';

export default Header;
