import styled from 'styled-components';
import colors from 'global/colors';

function Footer() {
    return (
        <FooterWrapper>
            <FooterText>{FOOTER_TEXT}</FooterText>
        </FooterWrapper>
    );
}

const FooterWrapper = styled.footer`
    align-items: center;
    background-color: ${colors.gray};
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const FooterText = styled.p`
    color: ${colors.white};
    font-size: 1.2rem;
    margin: 50px;
`;

const FOOTER_TEXT = '\u00A9 Amos Rose 2023';

export default Footer;
