export type Message = {
    name: string;
    email: string;
    subject: string;
    message: string;
};

export const blankMessage: Message = {
    name: '',
    email: '',
    subject: '',
    message: '',
};
