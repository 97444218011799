import Header from 'components/Header';
import HomeSection from 'components/HomeSection';
import AboutSection from 'components/AboutSection';
import SkillSection from 'components/skillSection';
import ContactSection from 'components/contactSection';
import Footer from 'components/Footer';
import './App.css';

function App(): JSX.Element {
    return (
        <div className="App">
            <Header />
            <HomeSection />
            <AboutSection />
            <SkillSection />
            <ContactSection />
            <Footer />
        </div>
    );
}

export default App;
