import styled from 'styled-components';

interface Props {
    backgroundColor: string;
    polygonColor: string;
}

function Arrow(props: Props) {
    const { backgroundColor, polygonColor } = props;

    return (
        <ArrowWrapper backgroundColor={backgroundColor}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
            >
                <StyledPolygon
                    polygonColor={polygonColor}
                    points="0,0 100,0 50,100"
                ></StyledPolygon>
            </svg>
        </ArrowWrapper>
    );
}

export default Arrow;

const ArrowWrapper = styled.div<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
    height: 100px;
    width: 100%;
`;

const StyledPolygon = styled.polygon<{ polygonColor: string }>`
    fill: ${(props) => props.polygonColor};
`;
