import styled from 'styled-components';

interface Props {
    name: string;
    logo: string;
}

function Skill(props: Props) {
    const { name, logo } = props;

    return (
        <SkillWrapper>
            <SkillLogoWrapper>
                <SkillLogo src={logo} alt={`${name} logo`} />
            </SkillLogoWrapper>
            <SkillName>{name}</SkillName>
        </SkillWrapper>
    );
}

const SkillWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
`;

const SkillName = styled.p`
    font-size: 1.8rem;
    text-align: center;
`;

const SkillLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  height; 75px;
  justify-content: center;
  width: 75px;
`;

const SkillLogo = styled.img`
    height: 70px;
    margin-bottom: 10px;
    max-width: 100%;
`;

export default Skill;
