import React from 'react';
import styled from 'styled-components';
import breakpoints from 'global/breakPoints';
import { SkillInfo, skillsList } from './fixtures';
import Skill from './Skill';

function SkillSection() {
    return (
        <SkillSectionWrapper id="skills">
            <SkillsTitleWrapper>
                <SkillsTitle>{skillsTitleText}</SkillsTitle>
                <SkillsBreakpointTitle>
                    {skillsBreakpointTitleText}
                </SkillsBreakpointTitle>
            </SkillsTitleWrapper>
            <SkillsList>
                {skillsList.map((skill: SkillInfo) => (
                    <Skill
                        key={skill.name}
                        name={skill.name}
                        logo={skill.logo}
                    />
                ))}
            </SkillsList>
        </SkillSectionWrapper>
    );
}

const SkillSectionWrapper = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SkillsTitleWrapper = styled.div`
    font-size: 3.6rem;
    margin-top: 100px;
    text-align: center;
`;

const SkillsTitle = styled.h2`
    display: block;

    @media (max-width: ${breakpoints.small}) {
        display: none;
    }
`;

const SkillsBreakpointTitle = styled.h2`
    display: none;

    @media (max-width: ${breakpoints.small}) {
        display: block;
    }
`;

const SkillsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px auto;
    max-width: 80%;
    width: 1200px;
`;

const skillsTitleText = 'Languages / Technologies / Skills';

const skillsBreakpointTitleText = 'Skills';

export default SkillSection;
