import styled from 'styled-components';
import colors from 'global/colors';
import ContactForm from './contactForm';
import ContactIcons from 'components/contactIcons';

function ContactSection() {
    return (
        <ContactWrapper id="contact">
            <ContactTitle>{CONTACT_TITLE_TEXT}</ContactTitle>
            <ContactForm />
            <ContactIcons
                baseColor={colors.whiteTransparent}
                hoverColor={colors.white}
            />
        </ContactWrapper>
    );
}

const ContactWrapper = styled.section`
    align-items: center;
    background-color: ${colors.gray};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ContactTitle = styled.h2`
    color: ${colors.white};
    font-size: 3.6rem;
    margin-top: 75px;
`;

const CONTACT_TITLE_TEXT = 'Contact Me';

export default ContactSection;
