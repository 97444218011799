const black = '#191919';
const blackTransparent = 'rgba(0, 0, 0, 0.8)';
const darkGray = '#343434';
const gray = '#3e3e3e';
const green = '#689689';
const white = '#ffffff';
const whiteTransparent = 'rgba(255, 255, 255, 0.8)';

const colors = {
    black,
    blackTransparent,
    darkGray,
    gray,
    green,
    white,
    whiteTransparent,
};

export default colors;
